import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import LayoutCenterColumn from "@components/layout-center-column";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import Sponsors from "@components/sponsors/sponsors-component";
import TreeboMembersComponent from "@sections/treebo-members/treebo-members-component";
import { useImage } from "@queries/use-image";

export default function Voluntariar() {
  const { getFluidImageNameAndExtension } = useImage();
  const seoImage = getFluidImageNameAndExtension("bootcamp-science", "jpg");

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 90 }} mobile={{ margin: 180 }} />

      <TreeboMembersComponent />

      <EmptySpace desktop={{ margin: 180 }} mobile={{ margin: 50 }} />

      <FullBleed>
        <LayoutCenterColumn>
          <Sponsors />
        </LayoutCenterColumn>
      </FullBleed>

      <EmptySpace desktop={{ margin: 180 }} mobile={{ margin: 80 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="Junta-te à Treebo e vem construir o futuro"
        description="Descobre como podes juntar-te à treebo e ajudar a desenvolver o talento das crianças e jovens de Portugal."
        image={seoImage}
      />
    </Layout>
  );
}
