import {
  Container,
  Content,
  Media,
  Paragraph,
  StyledImg,
  StyledLink,
  Title,
} from "./styles";
import Button from "@components/button";
import React from "react";
import { useImage } from "@queries/use-image";

const TreeboMembersComponent = () => {
  const { getFluidImageNameAndExtension } = useImage();
  return (
    <Container>
      <Content>
        <Title>Sê um membro da treebo</Title>
        <Paragraph>
          Os voluntários são o núcleo da forma como servimos a treebo. Não
          precisas de ser um génio da ciência nem um doutorado em matemática
          avançada. Só precisas de acreditar nos alunos a que nos dedicamos e
          também de ter vontade de aprender em conjunto!
        </Paragraph>
        <StyledLink to="/contactos/">
          <Button size="large" color="white" bg="orange" border="none">
            contacta-nos
          </Button>
        </StyledLink>
      </Content>

      <Media>
        <StyledImg
          fluid={getFluidImageNameAndExtension("voluntariado@3x", "jpg")}
          alt="Estudantes a colaborar com a professora"
        />
      </Media>
    </Container>
  );
};

export default TreeboMembersComponent;
