import Img from "gatsby-image";
import { Link } from "gatsby";
import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  max-height: 423px;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: unset;
  }
`;

export const Content = styled.div`
  flex: 1;
  max-width: 474px;
  padding-right: 30px;
  align-self: end;

  @media (max-width: 768px) {
    max-width: unset;
    padding-right: 0;
  }
`;

export const Title = styled.h1`
  margin-bottom: 13px;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const Media = styled.div`
  flex: 1;
  max-width: 635px;

  @media (max-width: 768px) {
    margin-top: 60px;
    max-width: unset;
    height: 350px;
  }
`;

export const StyledImg = styled(Img)`
  height: 100%;

  @media (max-width: 768px) {
    height: 350px;
    picture {
      object-fit: cover;
    }
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 52px;
  margin-bottom: 42px;

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: unset;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: unset;
  }
`;
